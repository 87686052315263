import { ChangeDetectorRef, Component, OnDestroy, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastManager } from '@blocks/toast/toast.manager';
import { ValidationHelper } from '@helpers/validation.helper';
import { OnBoardingStep, Recruitment } from '@interfaces/onboarding.interface';
import { Reference } from '@interfaces/reference.interface';
import { Timezone } from '@interfaces/register.interface';
import { UserProfile } from '@interfaces/talent.interface';
import { OnBoardingService } from '@services/onboarding.service';
import { ReferenceService } from '@services/reference.service';
import { SnackbarService } from '@services/snackbar.service';
import { StoreService } from '@services/store.service';
import dayjs from 'dayjs';
import { Subject, combineLatest } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { MAX_LENGTH, URL_PATTERN } from 'src/app/shared/constants';
import { TIMEZONE } from 'src/app/shared/constants/timezone';
import CustomEditor from 'ckeditor5-ng';
import { CompanyProfile } from '@interfaces/corporate.interface';
import { isEmpty } from 'lodash';
import { Clipboard } from '@angular/cdk/clipboard';
import { StorageHelper } from '@helpers/storage.helper';
import { InterviewFormComponent } from '@forms/interview-form/interview-form.component';
import { OnBoardingFormComponent } from '@forms/on-boarding-form/on-boarding-form.component';

@Component({
  selector: 'recruitment-steps',
  templateUrl: './recruitment-steps.component.html',
  styleUrls: ['./recruitment-steps.component.scss']
})
export class RecruitmentStepsComponent implements OnDestroy {
  public selectedFile: File | null = null;
  public selectedFileName: string | null = null;
  public selectedNDAFileName: string | null = null;
  public isUploadingFile = false;
  public isLoading = false;
  public activeStep = 'test';
  public recruitmentData !: Recruitment;
  public onBoardingId = '';
  public notes = '';
  public rescheduledDate = '';
  public rescheduledTime = '';
  public rejectedReason!: FormControl;
  public meetingLink!: FormControl;
  public meetingLink2!: FormControl;
  public meetingLink3!: FormControl;
  public answeredLink!: FormControl;
  public minDate = dayjs().format('YYYY-MM-DD');
  public onBoardingData !: Recruitment;
  public loginAs = 'TALENT';
  public isTechnicalTestExist = false;
  public isOnEditWelcomeSection = false;
  public isInterviewTestExist = false;
  public technicalTestApprovalForm !: FormGroup;
  public interviewTestApprovalForm !: FormGroup;

  private destroy$ = new Subject<void>();
  public skillLevels: Reference[] = [];
  public solvingSkills: Reference[] = [];
  public timezoneList: Timezone[] = TIMEZONE;
  public recruitTypeList: Reference[] = [];
  public modalFormType: string | null = null;
  public userProfile: UserProfile | null = null;
  public readonly interviewStatus = {
    invitationSent: 1,
    accepted: 2,
    reschedule: 3
  }
  public onboardingForm !: FormGroup<{
    description: FormControl<string>,
    onboardingDate: FormControl<string>,
    onboardingTime: FormControl<string>,
    officeId: FormControl<string>,
  }>;
  public welcomeForm !: FormGroup<{
    description: FormControl<string>,
  }>;
  public Editor = CustomEditor;
  public branchList: any[] = [];
  public companyProfile!: CompanyProfile | null;
  public isNDAFileValid = false;
  public isPlacementOwner = false;

  @ViewChildren(InterviewFormComponent) interviewForms!: QueryList<InterviewFormComponent>; // Referensi ke semua komponen anak
  public interviewTypes: string[] = ['interview-1', 'interview-2', 'interview-3']; // Daftar interview types

  @ViewChild(OnBoardingFormComponent) onboardingForm2!: OnBoardingFormComponent; // Referensi ke semua komponen anak

  constructor(
    private cdr: ChangeDetectorRef,
    private onBoardingService: OnBoardingService,
    private toastManager: ToastManager,
    private snackbarService: SnackbarService,
    private storeService: StoreService,
    private referenceService: ReferenceService,
    private router: Router,
    private clipboard: Clipboard,
    private route: ActivatedRoute,
  ) {
    this.rejectedReason = new FormControl('');
    this.meetingLink = new FormControl('', [Validators.required, Validators.pattern(URL_PATTERN)]);
    this.meetingLink2 = new FormControl('', [Validators.required, Validators.pattern(URL_PATTERN)]);
    this.meetingLink3 = new FormControl('', [Validators.required, Validators.pattern(URL_PATTERN)]);
    this.answeredLink = new FormControl('', [Validators.required, Validators.pattern(URL_PATTERN)]);
    this.initForm();
  }

  async ngOnInit() {
    combineLatest([
      this.onBoardingService.selectedOnBordingId$,
      this.storeService.loginAs$,
      this.storeService.userProfile$,
      this.storeService.companyProfile$,
    ])
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        async ([
          onBoardingId,
          loginAs,
          userProfile,
          companyProfile,
        ]: [any, any, any, any]) => {
          this.loginAs = loginAs;
          this.userProfile = userProfile;
          this.companyProfile = companyProfile;

          if (onBoardingId) {
            this.activeStep = 'test';
            this.onBoardingId = onBoardingId;
            if (StorageHelper.getCurrentLoggedUser()) {
              await this.onBoardingIdChanged();
            }
          }

          if (this.companyProfile) {
            if (!isEmpty(this.companyProfile?.mainOffice)) {
              this.branchList = [this.companyProfile.mainOffice];
            }

            if (!isEmpty(this.companyProfile?.branches)) {
              this.branchList = this.branchList.concat(this.companyProfile.branches)
            }
          }
        })
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public isPendingReschedule(interviewStep: string) {
    const getInterviewData = (step: string) => {
      switch (step) {
        case 'INTERVIEW-1': return this.recruitmentData.interviewData;
        case 'INTERVIEW-2': return this.recruitmentData.interviewData2;
        case 'INTERVIEW-3': return this.recruitmentData.interviewData3;
        default: return null;
      }
    }

    const interviewData = getInterviewData(interviewStep);
    if (!interviewData) {
      return false;
    }

    const isRescheduleRejectedByCorpo = interviewData.talentResponse === 'rejected' && interviewData.corporateResponse === 'rejected';
    return !isRescheduleRejectedByCorpo && interviewData.scheduleStatus === this.interviewStatus.reschedule && interviewData.status === 'RESCHEDULE';
  }

  public getInactiveStepClass(stepName: OnBoardingStep) {
    if (this.recruitmentData) {
      const isUncompletedStep = this.recruitmentData.uncompletedSteps.some(step => step === stepName);
      if (isUncompletedStep) {
        return 'step-card-grey'
      }
      if (stepName === 'test' && this.recruitmentData.testData) {
        if (this.recruitmentData.testData.answerLink) {
          return 'step-card-success';
        }

        if (this.recruitmentData.testData.testType === 2) {
          return 'step-card-danger';
        }

        return 'step-card-grey';
      }

      if (stepName === 'interview' && this.recruitmentData.interviewData) {

        if (this.recruitmentData.lastStep === 'interview') {
          return 'step-card-primary';
        }

        const isFirstInterviewRejected = this.recruitmentData.interviewData?.status === 'REJECTED'
        const isSecondInterviewRejected = this.recruitmentData.interviewData2?.status === 'REJECTED'
        const isThirdviewRejected = this.recruitmentData.interviewData2?.status === 'REJECTED'
        const interviewRejected = isFirstInterviewRejected || isSecondInterviewRejected || isThirdviewRejected;

        if (this.recruitmentData.interviewData.status && !interviewRejected) {
          return 'step-card-success';
        }

        if (interviewRejected) {
          return 'step-card-danger';
        }

        return 'step-card-grey';
      }

      if (stepName === 'on-boarding' && this.recruitmentData.onBoardingData) {
        if (this.recruitmentData.onBoardingData.status === 'APPROVED') {
          return 'step-card-success';
        }

        if (this.recruitmentData.onBoardingData.status === 'REJECTED') {
          return 'step-card-danger';
        }

        if (this.recruitmentData.lastStep === "nda" || this.recruitmentData.lastStep === "welcome") {
          return 'step-card-success';
        }

        return 'step-card-grey';
      }

      if (stepName === 'nda' && this.recruitmentData.onBoardingData) {
        if (this.recruitmentData.testData.answerLink) {
          return 'step-card-success';
        }

        if (this.recruitmentData.testData.testType === 2) {
          return 'step-card-danger';
        }

        return 'step-card-grey';
      }

      if (stepName === 'welcome' && this.recruitmentData.lastStep === "welcome") {
        return 'step-card-success';
      }
      return 'step-card-grey';
    }

    return 'step-card-grey';
  }

  private initForm() {
    this.technicalTestApprovalForm = new FormGroup({
      skillLevel: new FormControl<string>({
        value: '',
        disabled: false
      }, { validators: [Validators.required], nonNullable: true }),
      solvingSkill: new FormControl<string>({
        value: '',
        disabled: false
      }, { validators: [Validators.required], nonNullable: true }),
      handsOn: new FormControl<string>({
        value: '',
        disabled: false
      }, { validators: [Validators.required], nonNullable: true }),
      overallScore: new FormControl<string>({
        value: '',
        disabled: false
      }, { validators: [Validators.required, Validators.min(1), Validators.max(100),], nonNullable: true }),
      notes: new FormControl<string>({
        value: '',
        disabled: false
      }, { validators: [Validators.required], nonNullable: true }),
    })

    this.interviewTestApprovalForm = new FormGroup({
      recruitType: new FormControl<string>({
        value: '',
        disabled: false
      }, { validators: [Validators.required], nonNullable: true }),
      attitude: new FormControl<string>({
        value: '',
        disabled: false
      }, { validators: [Validators.required], nonNullable: true }),
      communication: new FormControl<string>({
        value: '',
        disabled: false
      }, { validators: [Validators.required], nonNullable: true }),
      experience: new FormControl<string>({
        value: '',
        disabled: false
      }, { validators: [Validators.required], nonNullable: true }),
      notes: new FormControl<string>({
        value: '',
        disabled: false
      }, { validators: [Validators.required], nonNullable: true }),
    })

    this.onboardingForm = new FormGroup({
      description: new FormControl<string>({
        value: '',
        disabled: false
      }, { validators: [Validators.required, ValidationHelper.customCkEditorLengthValidator(MAX_LENGTH.TEXT_AREA_LARGE)], nonNullable: true }),
      onboardingDate: new FormControl<string>({
        value: '',
        disabled: false
      }, { validators: [Validators.required], nonNullable: true }),
      onboardingTime: new FormControl<string>({
        value: '',
        disabled: false
      }, { validators: [Validators.required], nonNullable: true }),
      officeId: new FormControl<string>({
        value: '',
        disabled: false
      }, { validators: [Validators.required], nonNullable: true }),
    });

    this.welcomeForm = new FormGroup({
      description: new FormControl<string>({
        value: '',
        disabled: false
      }, { validators: [Validators.required, ValidationHelper.customCkEditorLengthValidator(MAX_LENGTH.TEXT_AREA_LARGE)], nonNullable: true }),
    });
  }

  public onEditWelcomeSection() {
    this.isOnEditWelcomeSection = true;
    if (!this.recruitmentData.welcomeData || !this.recruitmentData.welcomeData.welcomeNote) {
      this.welcomeForm.controls.description.setValue(this.getDefaultWelcomeText());
    }

    if (this.recruitmentData.welcomeData && this.recruitmentData.welcomeData.welcomeNote) {
      this.welcomeForm.controls.description.setValue(this.recruitmentData.welcomeData.welcomeNote);
    }
  }

  copyText(copiedText: string, type: string) {
    this.clipboard.copy(copiedText);
    this.toastManager.showSuccess(`${type} copied to clipboard`);
  }

  public isSendInvitationButtonShown(type: string) {
    let isInMeetingSetupSchedule = false;

    if (type === 'interview') {
      if (!this.recruitmentData.interviewData) {
        isInMeetingSetupSchedule = true
      }
      if (!this.recruitmentData.interviewData2 && this.recruitmentData.interviewData.status === 'APPROVED') {
        isInMeetingSetupSchedule = true
      }
      if (!this.recruitmentData.interviewData3 && this.recruitmentData.interviewData2 && this.recruitmentData.interviewData2.status === 'APPROVED') {
        isInMeetingSetupSchedule = true
      }
      return this.isPlacementOwner && this.recruitmentData.lastStep === 'interview'
        && this.recruitmentData.status === 1 && isInMeetingSetupSchedule
    }

    if (type === 'onboarding') {
      if (!this.recruitmentData.onBoardingData) {
        isInMeetingSetupSchedule = true
      }

      return this.isPlacementOwner //&& this.recruitmentData.lastStep === 'onboard'
        && this.recruitmentData.status === 1 && isInMeetingSetupSchedule
    }
    return isInMeetingSetupSchedule
  }

  public async saveWelomeData() {
    try {
      this.isLoading = true;
      const payload = {
        onboardingId: this.onBoardingId,
        welcomeNote: this.welcomeForm.controls.description.value
      }
      await this.onBoardingService.saveWelcomeData(payload);
      this.toastManager.showSuccess('Save welcome data successfully');
      this.isOnEditWelcomeSection = false;
      await this.onBoardingIdChanged();
    } catch (error: any) {
      this.toastManager.showError('Save welcome data failed', error.message)
    } finally {
      this.isLoading = false;
    }
  }

  public async onBoardingIdChanged() {
    this.resetState();
    try {
      this.isLoading = true;
      const onBoardingData = await this.onBoardingService.getOnBoardingDataById(this.onBoardingId);
      this.cdr.detectChanges();
      this.recruitmentData = onBoardingData;
      this.activeStep = onBoardingData.lastStep;
      const loggedUser = StorageHelper.getCurrentLoggedUser();
      if (onBoardingData.assigneeId === loggedUser?.ext) {
        this.isPlacementOwner = true
      }
      this.isTechnicalTestExist = !!this.recruitmentData.testData;
      this.isInterviewTestExist = !!this.recruitmentData.interviewData;
      if (this.recruitmentData.testData?.answerLink) {
        this.selectedFileName = this.recruitmentData.testData.answerLink;
      }
      if (this.recruitmentData.interviewData?.invitationLink) {
        this.meetingLink.setValue(this.recruitmentData.interviewData?.invitationLink)
      }
      if (this.recruitmentData.interviewData2?.invitationLink) {
        this.meetingLink2.setValue(this.recruitmentData.interviewData2?.invitationLink)
      }
      if (this.recruitmentData.interviewData3?.invitationLink) {
        this.meetingLink3.setValue(this.recruitmentData.interviewData3?.invitationLink)
      }
    } catch (error: any) {
      this.toastManager.showError('Get onboarding data failed', error.message)
    } finally {
      this.isLoading = false;
    }
  }

  public resetState() {
    this.activeStep = 'test';
    this.selectedFile = null;
    this.selectedFileName = null;
    this.selectedNDAFileName = null;
    this.answeredLink.reset();
    this.notes = '';
    this.rescheduledDate = '';
    this.rescheduledTime = '';
    this.meetingLink.reset();
    this.meetingLink2.reset();
    this.meetingLink3.reset();
    this.rejectedReason.reset();
  }

  public eligibleMoveTo(step: string) {
    if (step == 'welcome') {
      return this.isPlacementOwner
        && this.recruitmentData.lastStep === 'nda'
        && this.recruitmentData.ndaData && this.recruitmentData.ndaData.corporateNdaLink
        && this.recruitmentData.ndaData.talentNdaLink
    }
    return false;
  }

  public async setMeetingLink(interviewType: string) {

    let meetingLink = '';
    if (interviewType === 'interview-1') {
      if (!this.meetingLink.valid) {
        this.toastManager.showError('Set meeting link failed', 'Meeting link should be valid url')
        return;
      }
      meetingLink = this.meetingLink.value
    }

    if (interviewType === 'interview-2') {
      if (!this.meetingLink2.valid) {
        this.toastManager.showError('Set meeting link failed', 'Meeting link should be valid url')
        return;
      }
      meetingLink = this.meetingLink2.value
    }

    if (interviewType === 'interview-3') {
      if (!this.meetingLink3.valid) {
        this.toastManager.showError('Set meeting link failed', 'Meeting link should be valid url')
        return;
      }
      meetingLink = this.meetingLink3.value
    }

    try {
      this.isLoading = true;
      await this.onBoardingService.setMeetingLink(this.onBoardingId, meetingLink, interviewType);
      this.toastManager.showSuccess('Set meeting link has submitted successfully');
      await this.onBoardingIdChanged();
    } catch (error: any) {
      this.toastManager.showError('Set meeting link failed', error.message)
    } finally {
      this.isLoading = false;
    }
  }

  public getOnboardingOffice() {
    if (this.recruitmentData.onBoardingData) {
      const { name, city, country, timezone } = this.recruitmentData.onBoardingData;
      return `${name} - ${city}, ${country}, ${timezone}`;
    }
    return 'Not found'
  }

  public hasSetOnboardingData() {
    return this.recruitmentData.onBoardingData && this.recruitmentData.onBoardingData.message;
  }

  public async onNDAFileSelected(event: any) {
    const inputFile = event.target as HTMLInputElement;
    if (inputFile.files && inputFile.files.length > 0) {
      this.selectedFile = event.target.files[0];
      this.selectedNDAFileName = inputFile.files[0].name;
      try {
        ValidationHelper.uploadFileValidator(inputFile);
        this.isNDAFileValid = true;
      } catch (error: any) {
        this.isNDAFileValid = false;
        this.toastManager.showError('Upload file validation', error.message)
      }
    } else {
      this.selectedNDAFileName = null;
      this.selectedFile = null;
      this.isUploadingFile = false;
    }
  }

  public async uploadNDA() {
    try {
      this.isUploadingFile = true;
      await this.onBoardingService.uploadNDA(this.onBoardingId, this.selectedFile, this.loginAs);
      this.toastManager.showSuccess('NDA file has uploaded successfully');
      this.onBoardingService.setSelectedOnBoardingId(this.onBoardingId);
      this.resetState();
      this.onCloseModal();
    } catch (error: any) {
      this.toastManager.showError('Upload failed', error.message)
    } finally {
      this.isUploadingFile = false;
      this.isNDAFileValid = false;
    }
  }

  public async onFileSelected(event: any, type: string) {
    const inputFile = event.target as HTMLInputElement;
    if (inputFile.files && inputFile.files.length > 0) {
      this.isUploadingFile = true;
      try {
        ValidationHelper.uploadFileValidator(inputFile);
        this.selectedFile = event.target.files[0];
        if (type === 'testResult') {
          this.selectedFileName = inputFile.files[0].name;
          await this.onBoardingService.uploadTestResult(this.onBoardingId, this.selectedFile);
          this.toastManager.showSuccess('Test result file has uploaded successfully');
          this.onBoardingService.setSelectedOnBoardingId(this.onBoardingId);
        }
        this.selectedFileName = null;
        this.selectedNDAFileName = null;
        this.selectedFile = null;
      } catch (error: any) {
        this.toastManager.showError('Upload failed', error.message)
      } finally {
        this.isUploadingFile = false;
      }
    } else {
      this.selectedFileName = null;
      this.selectedFile = null;
      this.isUploadingFile = false;
    }
  }

  public async removeNDA() {
    try {
      await this.onBoardingService.removeNDA(this.onBoardingId);
      this.toastManager.showSuccess('NDA file has removed successfully');
      this.onCloseModal()
      this.onBoardingService.setSelectedOnBoardingId(this.onBoardingId);
    } catch (error: any) {
      this.toastManager.showError('Remove NDA failed', error.message)
    } finally {
      this.isUploadingFile = false;
    }
  }

  public uploadTestResult() {
    const fileInput = document.getElementById('testResult');
    if (fileInput) {
      fileInput.click();
    }
  }

  public selectNDAFile() {
    const fileInput = document.getElementById('nda');
    if (fileInput) {
      fileInput.click();
    }
  }

  public async saveAnswerLink() {
    try {
      this.isLoading = true;
      if (!URL_PATTERN.test(this.answeredLink.value)) {
        this.toastManager.showError('Save answer link failed', 'Please enter valid answer link')
        return;
      }
      await this.onBoardingService.saveAnswerLink(this.onBoardingId, this.answeredLink.value);
      this.onBoardingService.setSelectedOnBoardingId(this.onBoardingId)
      this.toastManager.showSuccess('Save answer link has submitted successfully')
    } catch (error: any) {
      this.toastManager.showError('Upload failed', error.message)
    } finally {
      this.isLoading = false;
    }
  }

  private mapTechnicalTestPayload() {
    return {
      onboardingId: this.onBoardingId,
      skillLevel: this.technicalTestApprovalForm.get('skillLevel')?.getRawValue(),
      solvingSkill: this.technicalTestApprovalForm.get('solvingSkill')?.getRawValue(),
      handsOn: this.technicalTestApprovalForm.get('handsOn')?.getRawValue(),
      overallScore: this.technicalTestApprovalForm.get('overallScore')?.getRawValue(),
      notes: this.technicalTestApprovalForm.get('notes')?.getRawValue()
    }
  }

  private mapInterviewTestPayload(interviewType: string, isOnboard: boolean) {
    return {
      onboardingId: this.onBoardingId,
      recruitType: this.interviewTestApprovalForm.get('recruitType')?.getRawValue(),
      attitude: this.interviewTestApprovalForm.get('attitude')?.getRawValue(),
      communication: this.interviewTestApprovalForm.get('communication')?.getRawValue(),
      experience: this.interviewTestApprovalForm.get('experience')?.getRawValue(),
      notes: this.interviewTestApprovalForm.get('notes')?.getRawValue(),
      interviewType,
      isOnboard
    }
  }

  isAbleToSetMeetingLink(interviewStep: string) {
    if (interviewStep === 'firstInterview') {
      const interviewAccepted = this.recruitmentData.interviewData.scheduleStatus === this.interviewStatus.accepted
      const corpoRejectReschedule = this.recruitmentData.interviewData.talentResponse === 'rejected' && this.recruitmentData.interviewData.corporateResponse === 'rejected'
      const hasReschedule = this.recruitmentData.interviewData.status === 'RESCHEDULE';
      // return true;
      return (interviewAccepted || corpoRejectReschedule) && (!this.recruitmentData.interviewData.status || hasReschedule)
    }

    if (interviewStep === 'secondInterview') {
      const interviewAccepted = this.recruitmentData.interviewData2.scheduleStatus === this.interviewStatus.accepted
      const corpoRejectReschedule = this.recruitmentData.interviewData2.talentResponse === 'rejected' && this.recruitmentData.interviewData2.corporateResponse === 'rejected'
      const hasReschedule = this.recruitmentData.interviewData2.status === 'RESCHEDULE'
      return (interviewAccepted || corpoRejectReschedule) && (!this.recruitmentData.interviewData2.status || hasReschedule)
    }

    if (interviewStep === 'thirdInterview') {
      const interviewAccepted = this.recruitmentData.interviewData3.scheduleStatus === this.interviewStatus.accepted
      const corpoRejectReschedule = this.recruitmentData.interviewData3.talentResponse === 'rejected' && this.recruitmentData.interviewData3.corporateResponse === 'rejected'
      const hasReschedule = this.recruitmentData.interviewData3.status === 'RESCHEDULE'
      return (interviewAccepted || corpoRejectReschedule) && (!this.recruitmentData.interviewData3.status || hasReschedule)
    }

    return false
  }

  public isRejectedApplicant() {
    const rejectOnTechnicalTest = this.recruitmentData.testData && this.recruitmentData.testData.status === 'REJECT';
    const rejectOnFirstInterview = this.recruitmentData.interviewData && this.recruitmentData.interviewData.status === 'REJECTED';
    const rejectOnSecondInterview = this.recruitmentData.interviewData2 && this.recruitmentData.interviewData2.status === 'REJECTED';
    const rejectOnThridInterview = this.recruitmentData.interviewData3 && this.recruitmentData.interviewData3.status === 'REJECTED';
    const ndaAndWelcomeSection = ['nda', 'welcome'].includes(this.recruitmentData.lastStep);
    const isInProgress = this.recruitmentData.status === 1;
    return rejectOnTechnicalTest || rejectOnFirstInterview || rejectOnSecondInterview || rejectOnThridInterview || ndaAndWelcomeSection || !isInProgress
  }

  public async onTechnicalTestSubmit(result: string) {
    try {
      this.isLoading = true;
      const payload = this.mapTechnicalTestPayload();
      await this.onBoardingService.submitTechnicalTest(payload, result);
      this.onBoardingService.setSelectedOnBoardingId(this.onBoardingId)
      this.toastManager.showSuccess(`Technical test has ${result} successfully`)
      this.onCloseModal()
      if (result === 'rejected') {
        this.router.navigate(['/corporate/job'])
      }
    } catch (error: any) {
      this.toastManager.showError('Submit Failed', error.message)
    } finally {
      this.isLoading = false;
    }
  }

  public async onInterviewTestSubmit(result: string, isOnboard: boolean) {
    try {
      this.isLoading = true;
      const interviewType = this.getInterviewType('moveOnboard');
      if (interviewType === 'interview-3' && !isOnboard) {
        this.toastManager.showError('Move to next interview Failed', 'You only have 3 interviews process')
        return;
      }
      const payload = this.mapInterviewTestPayload(interviewType, isOnboard);
      await this.onBoardingService.submitInterviewTest(payload, result);
      this.onBoardingService.setSelectedOnBoardingId(this.onBoardingId)
      this.toastManager.showSuccess(`Interview test has ${result} successfully`)
      this.onCloseModal();
      if (result === 'rejected') {
        this.router.navigate(['/corporate/job'])
      }
    } catch (error: any) {
      this.toastManager.showError('Submit Failed', error.message)
    } finally {
      this.isLoading = false;
    }
  }

  public onStepCardClick(stepName: OnBoardingStep) {
    if (this.recruitmentData.uncompletedSteps.includes(stepName)) {
      this.toastManager.showError('Oops!!', `Last placement step is ${this.recruitmentData.lastStep.toUpperCase()}`)
      return;
    }

    this.activeStep = stepName;
    this.selectedFile = null;
    this.selectedFileName = null;
  }

  public async resignApplication() {
    try {
      this.isLoading = true;
      const payload = {
        onboardingId: this.onBoardingId,
        reason: this.rejectedReason.value,
        notes: this.notes
      }
      await this.onBoardingService.resignApplication(payload);
      this.toastManager.showSuccess('The application has rejected successfully');
      this.onCloseModal();
      this.resetState();
      this.storeService.setActiveSubNavbar('applications')
      this.router.navigate(['talent/applications']);
    } catch (error: any) {
      this.toastManager.showError('Reject Application Failed', error.message)
    } finally {
      this.isLoading = false
    }
  }

  public async rescheduleMeeting() {
    try {
      this.isLoading = true;
      const interviewType = this.getInterviewType('setupMeeting');
      const payload = {
        onboardingId: this.onBoardingId,
        date: this.rescheduledDate,
        time: this.rescheduledTime,
        interviewType
      }
      await this.onBoardingService.rescheduleMeetingInterview(payload);
      this.toastManager.showSuccess('The rescheduled meeting has requested successfully');
      this.onBoardingService.setSelectedOnBoardingId(this.onBoardingId)
      this.onCloseModal();
    } catch (error: any) {
      this.toastManager.showError('The rescheduled meeting failed', error.message)
    } finally {
      this.isLoading = false
    }
  }

  public async acceptRescheduledTime() {
    try {
      this.isLoading = true;
      const interviewType = this.getInterviewType('setupMeeting');
      await this.onBoardingService.acceptRescheduledTime(this.onBoardingId, interviewType);
      this.toastManager.showSuccess('The resheduled time has approved successfully')
      this.onBoardingService.setSelectedOnBoardingId(this.onBoardingId)
      this.onCloseModal();
    } catch (error: any) {
      this.toastManager.showError('The rescheduled meeting failed', error.message)
    } finally {
      this.isLoading = false
    }
  }

  public async rejectRescheduledTime() {
    try {
      this.isLoading = true;
      const interviewType = this.getInterviewType('setupMeeting');
      await this.onBoardingService.rejectRescheduledTime(this.onBoardingId, interviewType);
      this.toastManager.showSuccess('The resheduled time has rejected successfully')
      this.onBoardingService.setSelectedOnBoardingId(this.onBoardingId)
      this.onCloseModal();
    } catch (error: any) {
      this.toastManager.showError('Reject rescheduled meeting failed', error.message)
    } finally {
      this.isLoading = false
    }
  }



  private getInterviewType(section = 'setupMeeting') {
    if (section === 'setupMeeting') {
      if (!this.recruitmentData.interviewData) {
        return 'interview-1'
      }
      if (this.recruitmentData.interviewData.status !== 'APPROVED' && this.recruitmentData.interviewData.scheduleStatus !== this.interviewStatus.accepted) {
        return 'interview-1'
      }

      if (!this.recruitmentData.interviewData2) {
        return 'interview-2'
      }
      if (this.recruitmentData.interviewData2.status !== 'APPROVED' && this.recruitmentData.interviewData2.scheduleStatus !== this.interviewStatus.accepted) {
        return 'interview-2'
      }

      if (!this.recruitmentData.interviewData3) {
        return 'interview-3'
      }
      if (this.recruitmentData.interviewData3.status !== 'APPROVED' && this.recruitmentData.interviewData3.scheduleStatus !== this.interviewStatus.accepted) {
        return 'interview-3'
      }
    }

    if (section === 'moveOnboard') {
      if (!this.recruitmentData.interviewData) {
        return 'interview-1'
      }
      if (this.recruitmentData.interviewData && !this.recruitmentData.interviewData2) {
        return 'interview-1'
      }

      if (this.recruitmentData.interviewData2 && !this.recruitmentData.interviewData3) {
        return 'interview-2'
      }

      if (this.recruitmentData.interviewData3) {
        return 'interview-3'
      }

      return 'interview-1'
    }

    return 'interview-1'
  }

  public async acceptMeeting() {
    try {
      this.isLoading = true;
      const interviewType = this.getInterviewType('setupMeeting');
      await this.onBoardingService.acceptMeetingInterview(this.onBoardingId, interviewType);
      this.toastManager.showSuccess('Your meeting has submitted successfully')
      this.onBoardingService.setSelectedOnBoardingId(this.onBoardingId)
      this.onCloseModal();
    } catch (error: any) {
      this.toastManager.showError('The rescheduled meeting failed', error.message)
    } finally {
      this.isLoading = false
    }
  }

  public async openModal(modalName: string, modalFormType: string | null) {
    if (this.loginAs === 'CORPORATE') {
      this.skillLevels = await this.referenceService.getReferenceListByType('SKILL_LEVEL');
      this.solvingSkills = await this.referenceService.getReferenceListByType('SCORE_RESULT');
      this.recruitTypeList = await this.referenceService.getReferenceListByType('RECRUIT_TYPE');
    }
    if (modalName === 'edit-onboarding-form') {
      const { message, officeId, date, time } = this.recruitmentData.onBoardingData
      this.onboardingForm.get('description')?.setValue(message)
      this.onboardingForm.get('officeId')?.setValue(officeId)
      this.onboardingForm.get('onboardingDate')?.setValue(date)
      this.onboardingForm.get('onboardingTime')?.setValue(time)
    }
    this.snackbarService.open(modalName);
    this.modalFormType = modalFormType;
  }

  public async onCloseModal() {
    this.snackbarService.close();;
    this.rejectedReason.setValue('');
    this.technicalTestApprovalForm.reset();
    this.interviewTestApprovalForm.reset();
    this.onboardingForm.reset();
    this.modalFormType = '';
  }

  public onRejectedReasonClick() {
    this.notes = '';
  }

  public openLink(link: string) {
    window.open(link, '_blank');
  }

  public getWordCount(formControl: string | null) {
    if (!formControl) {
      const notes = this.notes;
      const words = notes && notes.trim().split(/\s+/);
      return words?.length || 0;
    }

    if (formControl === 'technicalTestApprovalForm') {
      const notes = this.technicalTestApprovalForm.get('notes')?.getRawValue();
      const words = notes && notes.trim().split(/\s+/);
      return words?.length || 0;
    }

    if (formControl === 'onboardingForm') {
      const notes = this.onboardingForm.get('description')?.getRawValue();
      const words = notes && notes.trim().split(/\s+/);
      return words?.length || 0;
    }

    if (formControl === 'welcomeForm') {
      const notes = this.welcomeForm.get('description')?.getRawValue();
      const words = notes && notes.trim().split(/\s+/);
      return words?.length || 0;
    }

    return 0;
  }

  public getDefaultWelcomeText(): string {
    return `
    Welcome to the Team! <br> <br>

    We're excited to have you on board and can’t wait to start working together! <br> <br>

    Best regards,`
  }

  private mapOnboardingPayload() {
    const description = this.onboardingForm.get('description')?.getRawValue();
    const date = this.onboardingForm.get('onboardingDate')?.getRawValue();
    const time = this.onboardingForm.get('onboardingTime')?.getRawValue();
    const officeId = this.onboardingForm.get('officeId')?.getRawValue();
    return {
      onboardingId: this.onBoardingId,
      message: description,
      date,
      time,
      officeId,
    }
  }

  public async updateOnboardingMeeting() {
    try {
      this.isLoading = true;
      if (!this.onboardingForm.valid) {
        this.toastManager.showError('Onboarding invitation failed', 'Please complete interview form first');
        return;
      }
      const payload = this.mapOnboardingPayload();
      await this.onBoardingService.updateOnboardingInvitation(payload);
      this.toastManager.showSuccess('Your meeting invitation has submitted successfully');
      this.onBoardingService.setSelectedOnBoardingId(this.onBoardingId);
      this.onCloseModal()
    } catch (error: any) {
      this.toastManager.showError('Onboarding invitation failed', error.message);
    } finally {
      this.isLoading = false
    }
  }

  public sendInterviewMeeting() {
    const interviewForm = this.interviewForms.toArray()[0]; // Ambil instance berdasarkan index
    if (interviewForm) {
      interviewForm.sendInterviewMeeting(); // Panggil metode di komponen anak
    }
  }

  public sendOnboardingMeeting() {
    if (this.onboardingForm2) {
      this.onboardingForm2.sendOnboardingMeeting() // Panggil metode di komponen anak
    }
  }

  public async moveToNextPlacement(type: "APPROVED" | "REJECTED") {
    try {
      this.isLoading = true;
      if (this.recruitmentData.lastStep === 'on-boarding') {
        await this.onBoardingService.onboardingResult(this.onBoardingId, type);
        let typeStr = 'Approved';
        if (type === "REJECTED") {
          typeStr = 'Rejected';
        }
        this.toastManager.showSuccess(`Onboarding Talent has ${typeStr} successfully`);
      }
      if (this.recruitmentData.lastStep === 'nda') {
        await this.onBoardingService.approveNDA(this.onBoardingId);
        this.toastManager.showSuccess(`Continue to the next placement successfully`);
      }

      if (this.recruitmentData.lastStep === 'welcome') {
        await this.onBoardingService.markAsComplete(this.onBoardingId);
        this.onBoardingService.setSelectedOnBoardingId(this.onBoardingId);
        this.toastManager.showSuccess(`Mark as complete successfully`);
        this.router.navigate([], {
          relativeTo: this.route,
          queryParams: {
            status: 'accepted',
            onboardingId: this.onBoardingId,
          },
          queryParamsHandling: 'merge'
        });
      }

      this.onCloseModal()
    } catch (error: any) {
      this.toastManager.showError('Move to next placement failed', error.message);
    } finally {
      this.isLoading = false
    }
  }

  public async talentAcceptOnboardingSchedule() {
    try {
      this.isLoading = true;
      await this.onBoardingService.acceptOnboardingSchedule(this.onBoardingId);
      this.toastManager.showSuccess(`Accept onboarding schedule successfully`);
      this.onBoardingService.setSelectedOnBoardingId(this.onBoardingId);
    } catch (error: any) {
      this.toastManager.showError('Accept onboarding schedule failed', error.message);
    } finally {
      this.isLoading = false
    }
  }
}
