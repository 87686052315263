<div class="job-container2">
  <div class="job-form-recruitment">
    <form id="interviewForm" class="form-body-test" [formGroup]="interviewForm" (ngSubmit)="sendInterviewMeeting()">
      <div class="form-job">
        <div class="form-label-with-border-line">
          <div class="form-label-input" required>
            Setup an interview
          </div>
          <div class="break-line">
          </div>
        </div>
        <div class="input-text-space">
          <ckeditor id="description" name="description" [editor]="Editor" [disableTwoWayDataBinding]="false"
            formControlName="description"> </ckeditor>
          <div class="text-right">
            <label>{{ getWordCount() }}/{{ 'TEXT_AREA_LARGE' | maxLength }} words </label>
          </div>
          <div class="invalid-feedback-text"
            *ngIf="interviewForm.get('description')?.touched && interviewForm.get('description')?.hasError('required')">
            Description is required
          </div>
          <div class="invalid-feedback-text"
            *ngIf="interviewForm.get('description')?.touched && interviewForm.get('description')?.hasError('textAreaMaxLength')">
            Description length maximum {{ 'TEXT_AREA_LARGE' | maxLength }} words
          </div>
        </div>
        <div class="form-label-input">
          Setup an interview time
        </div>
        <div class="interview-input-group">
          <div class="ec-input-group-rounded input-text-space">
            <span class="ec-input-group-text">
              <i class="bi bi-calendar2-week-fill"></i>
            </span>
            <input class="ec-form-control-rounded ec-is-invalid" formControlName="scheduledDate" type="date"
              [min]="minDate">
            <div class="ec-invalid-feedback"
              *ngIf="interviewForm.controls.scheduledDate.touched && interviewForm.controls.scheduledDate.hasError('required')">
              Meeting date is required
            </div>
          </div>
          <div class="ec-input-group-rounded input-text-space">
            <span class="ec-input-group-text">
              <i class="bi bi-clock-fill"></i>
            </span>
            <input class="ec-form-control-rounded ec-is-invalid" formControlName="scheduledTime" type="time">
            <div class="ec-invalid-feedback"
              *ngIf="interviewForm.controls.scheduledTime.touched && interviewForm.controls.scheduledTime.hasError('required')">
              Meeting time is required
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
  <!-- <div style="margin: 10px;" class="btn-rounded bg-primary-soft" (click)="sendInterviewMeeting()">
    <label>
      Send Invitation
      <i class="bi bi-arrow-right">
      </i>
    </label>
  </div> -->
</div>
