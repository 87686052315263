<div class="job-container2">
  <div class="job-form-recruitment">
    <form id="onboardingForm" class="form-body-test" [formGroup]="onboardingForm" (ngSubmit)="sendOnboardingMeeting()">
      <div class="form-job">
        <div class="form-label-with-border-line">
          <div class="form-label-input" required>
            Setup an onboarding invitation
          </div>
          <div class="break-line">
          </div>
        </div>
        <div class="input-text-space">
          <ckeditor id="description" name="description" [editor]="Editor" [disableTwoWayDataBinding]="false"
            formControlName="description"> </ckeditor>
          <div class="text-right">
            <label>{{ getWordCount() }}/{{ 'TEXT_AREA_LARGE' | maxLength }} words </label>
          </div>
          <div class="invalid-feedback-text"
            *ngIf="onboardingForm.get('description')?.touched && onboardingForm.get('description')?.hasError('required')">
            Description is required
          </div>
          <div class="invalid-feedback-text"
            *ngIf="onboardingForm.get('description')?.touched && onboardingForm.get('description')?.hasError('textAreaMaxLength')">
            Description length maximum {{ 'TEXT_AREA_LARGE' | maxLength }} words
          </div>
        </div>
        <div class="form-label-input" required>
          Setup an onboarding time
        </div>
        <div class="interview-input-group">
          <div class="ec-input-group-rounded input-text-space">
            <span class="ec-input-group-text">
              <i class="bi bi-calendar2-week-fill"></i>
            </span>
            <input class="ec-form-control-rounded ec-is-invalid" formControlName="onboardingDate" type="date"
              [min]="minDate">
            <div class="ec-invalid-feedback"
              *ngIf="onboardingForm.controls.onboardingDate.touched && onboardingForm.controls.onboardingDate.hasError('required')">
              Onboarding date is required
            </div>
          </div>
          <div class="ec-input-group-rounded input-text-space">
            <span class="ec-input-group-text">
              <i class="bi bi-clock-fill"></i>
            </span>
            <input class="ec-form-control-rounded ec-is-invalid" formControlName="onboardingTime" type="time">
            <div class="ec-invalid-feedback"
              *ngIf="onboardingForm.controls.onboardingTime.touched && onboardingForm.controls.onboardingTime.hasError('required')">
              Onboarding time is required
            </div>
          </div>
        </div>
        <div class="form-label-input" required>
          Setup an onboarding location
        </div>
        <div class="ec-input-group-rounded input-text-space">
          <span class="ec-input-group-text">
            <i class="bi bi-pin-map-fill"></i>
          </span>
          <select type="text" formControlName="officeId" class="ec-form-select-rounded">
            <option selected disabled value="">Select Location</option>
            <option *ngFor="let branch of branchList" [value]="branch.id">{{ branch.name }} - {{ branch.timezone }}
            </option>
          </select>
        </div>
        <div class="invalid-feedback-text"
          *ngIf="onboardingForm.get('officeId')?.touched && onboardingForm.get('officeId')?.hasError('required')">
          Onboarding Location is required
        </div>
      </div>
    </form>
  </div>
</div>
